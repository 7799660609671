import parse from "html-react-parser";
import Modal from "./Modal.js";
import "./ProjectItem.css";
import "./Modal.css";
import { useState } from "react";

export default function ProjectItem({ project }) {
  let thumbnail = "https://cederdorff.github.io/img/logo512.webp";

  if (project._embedded && project._embedded["wp:featuredmedia"]) {
    thumbnail = project._embedded["wp:featuredmedia"][0].source_url;
  }

  let keywords = [];
  let buttons = "";
  if (project.acf.keywords.length > 0) {
    keywords = project.acf.keywords.split(',');
    keywords.forEach(element => {
      buttons += `<button class="keyword-button">${element.trim()}</button>`
    })
  }

  console.log(buttons)

  const [show, setShow] = useState(false);

  return (
    <article className="projectitem">
      <div class="card">
        <div class="card-header">
          <img
            src={project.acf.thumbnail}
            alt={
              project.acf.thumbnail
                ? project.acf.thumbnail.alt
                : thumbnail
            }
          />
        </div>
        <div class="card-body">
          <h2>{parse(project.title.rendered)}</h2>
          <div class="card-description">
            {project.acf.short_description
              ? parse(project.acf.short_description)
              : ""}
          </div>
        </div>
        <div class="card-footer">
          {project.acf.website ? (
            <a href={project.acf.website} target="_blank" rel="noreferrer">
              <button class="button">View</button>
            </a>
          ) : (
            <p>Link unavailable</p>
          )}

          {project.acf.full_description ? (
            <>
              <button onClick={() => setShow(true)} class="button button-outline">More info</button>
              <Modal onClose={() => setShow(false)} show={show}>
                <p>{project.acf.full_description}</p>
                Keywords:
                <div>{parse(buttons)}</div>
              </Modal>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </article>
  );
}
