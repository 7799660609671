import "./App.css";
import HomePage from "./pages/HomePage.js";
import AboutPage from "./pages/AboutPage.js";
import ContactPage from "./pages/ContactPage.js";
import ProjectsPage from "./pages/ProjectsPage.js";
import BlogPage from "./pages/BlogPage.js";
import Nav from "./components/Nav.js";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/LoginPage";

function App() {
  return (
    <>
      <main>
        <Nav />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="projects" element={<ProjectsPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
