// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCZI5-f2VIGQaDni9V89vIyuUow0gXC8cw",
  authDomain: "internship-blog-1b3e9.firebaseapp.com",
  projectId: "internship-blog-1b3e9",
  //storageBucket: "XXXXXXXXXXXXXXXXXX",
  //messagingSenderId: "XXXXXXXXXXXX",
  //appId: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;