import { SiHtml5 } from "react-icons/si";
import { SiCss3 } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { SiReact } from "react-icons/si";
import { SiGit } from "react-icons/si";
import { SiNpm } from "react-icons/si";
import { SiPhp } from "react-icons/si";
import { SiCsharp } from "react-icons/si";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { FaSquareFull } from "react-icons/fa";

export default function AboutPage() {
  return (
    <section className="about-page">
      <p className="question">Who are you?</p>
      <p>I am a junior software developer with a flair for puzzle solving.</p>
      <p>
        I love <span className="neon-highlight">puzzles</span> and solving them, and I like to look at programming
        problems as puzzles.
      </p>
      <p>
        "Fail faster" is a motto I try to keep myself to, meaning if you iterate
        through the possible solutions (and the bugs popping up) fast, you will
        learn from your mistakes faster and you will find the answer quicker.
      </p>

      <h3>Online</h3>
      <section className="column">
        <section>
        <p className="question">Why do you like programming?</p>
        <p>
          To me <span className="neon-highlight">programming is like speaking</span>. A set of instructions to convey a
          message.
        </p>
        <p>
          I heard somewhere before the expression "I speak C#" and I held it close
          to my heart ever since.
        </p>
        <p>I can quite quickly jump into any new language just by using it.</p>
        <p>
          It is the IT equivalent of being dropped into a new culture where you do
          not know the spoken language: you are bound to learn it, if you want to
          survive.
        </p>

        <p className="question">Are you up to date with the industry?</p>
        <p>
          I always try to keep up with the latest technologies any way I can,
          expanding my skills as software developer is always a top priority.
        </p>
        <p>I am currently learning <span className="neon-highlight">pyscript</span> in my free time.</p>

        <p className="question">Why full-stack?</p>
        <p>
          I feel like it gives me more job opportunities. I can be a <span className="neon-highlight">frontend </span>
          developer, a <span className="neon-highlight">backend</span> developer or <span className="neon-highlight">both at the same time</span>.
        </p>
        </section>

        <section>
        <p className="question">What is it the like the most about programming?</p>
        <p>
          Hmm... I'd say it is <span className="neon-highlight">the creation</span> aspect: magically willing something
          into existence just by typing words on the screen.
        </p>

        <p className="question">Any knowledge besides programming?</p>
        <p>
          Yes, since I am studying as a multimedia designer I also have <span className="neon-highlight">UX</span>, <span className="neon-highlight">UI</span> and <span className="neon-highlight">design knowledge</span>.
        </p>

        <p className="question">Do you work somewhere?</p>
        <p>
          Currently I'm working part-time at{" "}
          <a className="neon-highlight" href="https://natulique.com" target="_blank" rel="noreferrer">NATULIQUE</a> as a Student Developer.
        </p>
        <p>
          I'm also a dishwasher / barback at a popular cocktail bar in Aarhus called{" "}
          <a className="neon-highlight" href="https://gedulgt.dk" target="_blank" rel="noreferrer">Gedulgt</a>.
        </p>
        </section>
        
      </section>

      <p className="question">So what programming languages do you speak?</p>

      <p>I speak </p>
      <div className="languages">
        <div className="icon-container">HTML<SiHtml5 className="icon"/></div>
        <div className="icon-container">CSS<SiCss3 className="icon"/></div>
        <div className="icon-container">JavaScript<SiJavascript className="icon"/></div>
        <div className="icon-container">TypeScript<SiTypescript className="icon"/></div>
        <div className="icon-container">React<SiReact className="icon"/></div>
        <div className="icon-container">git<SiGit className="icon"/></div>
        <div className="icon-container">npm<SiNpm className="icon"/></div>
        <div className="icon-container">PHP<SiPhp className="icon"/></div>
        <div className="icon-container">C#<SiCsharp className="icon"/></div>
      </div>

      <h3>Offline</h3>
      <p className="question">Any hobbies?</p>

      <p>
        When I'm not coding I enjoy reading <span className="neon-highlight">Sherlock Holmes</span> stories, <span className="neon-highlight">baking </span>
        bread / pastries, <span className="neon-highlight">restoring</span> (or trying to restore) electronics and
        <span className="neon-highlight"> solving</span> puzzles (be it crosswords, a puzzle game or a Rubik's Cube type
        twisty puzzle).
      </p>

      <p>
        I'm also a <span className="neon-highlight">gamer</span>, but I'm not just playing, I also <span className="neon-highlight">develop games</span> when time
        allows me to do so. You can try two of my Puzzlescript games below.
        {" :)"}

        <div className="games-container">
          <div className="game-container">
            <p>Mye<VscCircleLargeFilled className="colored-green"/></p>
            <a href="https://flummox3d.itch.io/mye"><img alt="Mye game" src={require("../images/mye.webp")} /></a>
          </div>

          <div className="game-container">
            <p>Flow<FaSquareFull className="colored-blue"/></p>
            <a href="https://flummox3d.itch.io/flow"><img alt="Flow game" src={require("../images/flow.webp")} /></a>
          </div>
        </div>
      </p>

      <p>
        <span className="neon-highlight">Fun fact #1:</span> I can solve a Rubik's
        Cube in <span className="neon-highlight">42 seconds</span>.        
      </p>
      <p>
      <span className="neon-highlight">Fun fact #2:</span> I have a few <span className="neon-highlight">world records</span> in a small game called <span className="neon-highlight">Elasto Mania</span>.
      </p>

    </section>
  );
}
