export default function HomePage() {
  return (
    <div className="hero-text">
      <h1>
        <p className="jc-flex-end">Hello, I am Lajos Kis,</p>
        <p>a full-stack developer based in Aarhus, Denmark. <a className="neon-highlight" href="/about">{'>>'}</a> </p>
      </h1>

      <a href="/about"><img src={require("../images/profile_picture.webp")} alt="" /></a>

    </div>
  );
}
