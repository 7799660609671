import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = (props) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return (
    <div
      className={`modal ${props.show ? "show" : ""}`}
      onClick={props.onClose}
    >
      <div className="modal-content" onClick={(e) => e.preventDefault()}>
        <div className="modal-header">
          <AiOutlineClose onClick={props.onClose} className="neon-highlight" />
        </div>

        <div className="modal-body">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
