import { useEffect, useState } from "react";
import PostItem from "../components/PostItem.js";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Login from "./LoginPage.js";

export default function BlogPage() {
  const [userloggedin, setUserloggedin] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        setUserloggedin(true);
        console.log("uid", uid);
      } else {
        // User is signed out
        // ...
        setUserloggedin(false);
        console.log("user is logged out");
      }
    });
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const [posts, setPosts] = useState([]);
  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://wordpress.lajoskis.dk/wp-json/wp/v2/posts?_embed"
      );
      const data = await response.json();
      setPosts(data);
    }
    getData();
  }, []);

  return (
    userloggedin ?
    <section className="page">
      <div>
        <button onClick={handleLogout}>Logout</button>
      </div>
      <section className="grid-container">
        {posts.map((post) => (
          <PostItem key={post.id} post={post} />
        ))}
      </section>
    </section>
    :
    <Login />
  );
}
