import parse from "html-react-parser";

export default function PostItem({ post }) {
    let image = "https://cederdorff.github.io/img/logo512.webp";

    if (post._embedded && post._embedded["wp:featuredmedia"]) {
        image = post._embedded["wp:featuredmedia"][0].source_url;
    }

    console.log(post)

    return (
        <article className="postitem">
            {/* <img src={image} alt={post.title.rendered} /> */}
            <h2 className="post-title">{parse(post.title.rendered)}</h2>
            <div className="post-body">
                <p className="post-text">{parse(post.content.rendered)}</p>
                {/* <p>{post.acf.website ? <a href={post.acf.website}>Link to website</a> : <p>Link unavailable</p>}</p> */}
                <img src={post.acf.image ? post.acf.image.url : image} alt={post.acf.image ? post.acf.image.alt : "Default image"} />
            </div>
        </article>
    );
}
