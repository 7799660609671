import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Nav.css";
import { MdMenu } from "react-icons/md";
import { MdClose } from "react-icons/md";

export default function Nav() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState("closed-nav-height");

  const changeHeight = (toggled) => {
    if (toggled) {
      setHeight("opened-nav-height");
    } else {
      setHeight("closed-nav-height");
    }
  };

  const toggleNavbar = () => {
    setToggleMenu(!toggleMenu);

    changeHeight(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return (
    <nav className={"navbar " + height}>
      {(toggleMenu || screenWidth > 768) && (
        <ul className="list">
          <li>
            <NavLink to="/" end>Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">About me</NavLink>
          </li>
          <li>
            <NavLink to="/projects">Projects</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
          <li>
            <NavLink to="/blog">Blog</NavLink>
          </li>
        </ul>
      )}

      <span onClick={toggleNavbar} className="btn">
        {toggleMenu ? <MdClose /> : <MdMenu />}
      </span>
    </nav>
  );
}
