import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

export default function ContactPage() {
    return (
      <>
        <p>You can also find me on</p>
        <p><a className="contact-link" href="https:/github.com/KisLajos" target="_blank" rel="noreferrer">GitHub <FaGithub className="neon-highlight" /></a></p>
        <p><a className="contact-link" href="https:/linkedin.com/in/lajoskis" target="_blank" rel="noreferrer">LinkedIn <FaLinkedin className="neon-highlight" /></a></p>

        
      </>
    );
  }
  