import { useEffect, useState } from "react";
import ProjectItem from "../components/ProjectItem.js";

export default function ProjectsPage() {
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    async function getData() {
      const response = await fetch(
        "https://wordpress.lajoskis.dk/wp-json/wp/v2/projects?_embed"
      );
      const data = await response.json();
      console.log(data);
      if (data.data?.status !== 404) {
        setProjects(data);
      }
    }
    getData();
  }, []);

  return (
    <section className="page">
      <section className="grid-container-cards">
          {projects?.length > 0 ? projects.map((project) => (
            <ProjectItem key={project.id} project={project} />
          )) : "Waiting for data..."}
      </section>
    </section>
  );
}
